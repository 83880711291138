const config = {
    /*--
    --*/
    'server' : () => {
        var currentServer = window.location.hostname;
        return currentServer;
    },
    'version' : "1.4.0",

    /**
    * Array of all supported object by AvisenOne servers,
    * Can be filter by server
    * @param {filter} properties : Can appear in filter list
    * @param {add} properties : Can appear in add object list
    * @param {ota} properties : Ota type -> 0 - No ota / 1 - MQTT/Gateway ota / 2 - zigbee ota
    */
    'objects' : [
        /*"default" : {filter: 0, add: 0, ota: 0, tab_details:[{"name":"userName","editable":1,"pretreatment":"none"},{"name":"realName","editable":0,"pretreatment":"none"}],tab_parameters:[],tab_history:[]},*/
        { label: "VDoorBell", value: "VDoorBell", realName: 'access/vdp/', filter: 1, add: 1, ota: "http", servers: ['localhost','umii.avidsen.one','donkey.athemium.com'] },
        { label: "BoardGate", value: "BoardGate",  realName: 'gate/', parameters : ["mac_address"], filter: 1, add: 0, ota : "http", servers: ['localhost','umii.avidsen.one','donkey.athemium.com'] },
        { label: "AtHomeVDP", value: "AtHomeVDP", realName: 'access/vdp/', filter: 1, add: 1, ota : "http", servers: ['localhost','umii.avidsen.one','donkey.athemium.com'] },
        { label: "Rolling_Shutter_Ezsp", value: "Rolling_Shutter_Ezsp", realName: '', filter: 1, add: 1, ota : "zigbee", servers: ['profalux.avidsen.one'] },
        { label: "TEST", value: "TEST", realName: 'access/vdp/', filter: 1, add: 1, ota : "http", servers: [''] },
    ],    
    /**
    * List of supported objects by a specific server
    *
    */
    'supported_objects' : () => {
        console.log(config.server())
        return config.objects.filter((element) => element.servers.includes(config.server())) //TODO supprimer la liste des serveurs
    },
    /*--
    // Liste des commandes executables par une gateway
    --*/
    'gateway_commands' : [
        { label: "Command1"},
        { label: "Command2"}
    ]
};

export default config;