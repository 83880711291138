import Popup from 'reactjs-popup'
import Tabs from "../../components/Tabs/Tabs"
import dates from '../../utils/dates'
import ZigbeeRoutes from '../../components/Zdoctor/ZigbeeRoutes.js';

const SingleObject = ({callback, control, t, trigger, values, otaUrl, setOtaUrl, otaAction}) => {
    const statuses = new Map();

    if (values.hasOwnProperty("className")&&values.className==="Gate")
    {
        (values.statuses || []).forEach(element => {
            var obj;
            if(element.name === "__json_maintenance")
            {
                obj = JSON.parse(element.value);
                statuses.set("cycles",obj.cycles);
                statuses.set("last_maintenance",obj.lastAckDate);

            }
            else if (element.name === '__json_remotes_list')
            {
                let remote_list = [];
                obj = JSON.parse(element.value);
                obj.forEach(remote => {
                    remote_list.push(remote.id);
                });
                statuses.set("remote_list",remote_list);
            }
            else if (element.name === '__json_errors_history')
            {
                let errors_history = [];
                obj = JSON.parse(element.value);
                obj.forEach(error => {
                    var a = new Date(error.time * 1000);
                    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                    var year = a.getFullYear();
                    var month = months[a.getMonth()];
                    var date = a.getDate();
                    var hour = a.getHours();
                    var min = a.getMinutes();
                    var sec = a.getSeconds();
                    errors_history.push(date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec +" - Erreur "+error.code+":"+error.text);
                });
                statuses.set("errors_history",errors_history);
            }
            else if (element.name === '__json_commands_history')
            {
                let commands_history = [];
                obj = JSON.parse(element.value);
                obj.forEach(command => {
                    var a = new Date(command.time * 1000);
                    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                    var year = a.getFullYear();
                    var month = months[a.getMonth()];
                    var date = a.getDate();
                    var hour = a.getHours();
                    var min = a.getMinutes();
                    var sec = a.getSeconds();
                    commands_history.push(date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec +" - "+command.src+":"+command.cmd);
                });
                statuses.set("commands_history",commands_history);
            }
            else
                statuses.set(element.name,element.value);
        });
    }

    // TODO: rajouter un test sur le type d'objet pour décider si OTA est possible
    // vérifier ça dans config.json
    return(
        <Popup closeOnDocumentClick modal nested open={control} onClose={() => {
            callback(null)
          }} trigger={trigger}>
            { close => 
                <div className='popup'>
                    <h3 className='popup-title'>
                        {values.realName}{values.connected?<box-icon className='connection' color="#afe37b" type="solid" name="circle" /> : <box-icon color="red" type="solid" name="circle" />}
                    </h3>

                    <Tabs>
                        <div label={t('objects.details.menus.information')} icon="detail">
                            <div className="container">
                            <div className="row">
                                    <div className="col">
                                        {values.hasOwnProperty('realName')&&<h5>{t('objects.table.header.name')}</h5>}
                                        {values.hasOwnProperty('realName')&&<span>{values.realName}</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {values.hasOwnProperty('id')&&<h5>{t('objects.details.id')}</h5>}
                                        {values.hasOwnProperty('id')&&<span>{values.id}</span>}
                                    </div>
                                    <div className="col">
                                        {values.hasOwnProperty('userName')&&<h5>{t('objects.details.user_name')}</h5>}
                                        {values.hasOwnProperty('userName')&&<span>{values.userName}</span>}
                                    </div>
                                    <div className="col">
                                        {statuses.has('on_boarding')&&<h5>{t('objects.details.pairing_status')}</h5>}
                                        {statuses.has('on_boarding')&&<span>{statuses.get("on_boarding")}</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {values.hasOwnProperty('className') &&<h5>{t('objects.details.type')}</h5>}
                                        {values.hasOwnProperty('className') &&<span>{values.className}</span>}
                                    </div>
                                    <div className="col">
                                        {statuses.has("board_model")&&<h5>{t('objects.details.model')}</h5>}
                                        {statuses.has("board_model")&&<span>{statuses.get("board_model")}</span>}
                                    </div>
                                    <div className="col">
                                        {(statuses.has("board_firmware")||values.hasOwnProperty('firmware'))&&<h5>{t('objects.details.firmware')}</h5>}
                                        {(statuses.has("board_firmware")||values.hasOwnProperty('firmware'))&&<span>{statuses.has("board_firmware")?statuses.get("board_firmware"):values.firmware}</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {statuses.has("status") &&<h5>{t('objects.details.motors_state')}</h5>}
                                        {statuses.has("status") &&<span>{statuses.get("status")}</span>}
                                    </div>
                                    <div className="col">
                                        {statuses.has("halfopen_percent")&&<h5>{t('objects.details.halfopen_percent')}</h5>}
                                        {statuses.has("halfopen_percent")&&<span>{statuses.get("halfopen_percent")}</span>}
                                    </div>
                                    <div className="col">
                                        {statuses.has("board_status")&&<h5>{t('objects.details.board_state')}</h5>}
                                        {statuses.has("board_status")&&<span>{statuses.get("board_status")}</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {statuses.has("wifi_signal")&&<h5>{t('objects.details.wifi_signal')}</h5>}
                                        {statuses.has("wifi_signal")&&<span>{statuses.get("wifi_signal")}</span>}
                                    </div>
                                    <div className="col">
                                        {statuses.has("board_mac")&&<h5>{t('objects.details.wifi_mac')}</h5>}
                                        {statuses.has("board_mac")&&<span>{statuses.get("board_mac")}</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {statuses.has("bluetooth_status") &&<h5>{t('objects.details.bluetooth_status')}</h5>}
                                        {statuses.has("bluetooth_status") &&<span>{statuses.get("bluetooth_status")}</span>}
                                    </div>
                                    <div className="col">
                                        {statuses.has("bluetooth_mac")&&<h5>{t('objects.details.bluetooth_mac')}</h5>}
                                        {statuses.has("bluetooth_mac")&&<span>{statuses.get("bluetooth_mac")}</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {statuses.has("board_battery_level") &&<h5>{t('objects.details.battery_level')}</h5>}
                                        {statuses.has("board_battery_level") &&<span>{statuses.get("board_battery_level")}</span>}
                                    </div>
                                    <div className="col">
                                        {statuses.has("gate_cycles")&&<h5>{t('objects.details.cycles_nb')}</h5>}
                                        {statuses.has("gate_cycles")&&<span>{statuses.get("gate_cycles")}</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {statuses.has("remote_list")&&<h5>{t('objects.details.remote_list')}</h5>}
                                        {statuses.has("remote_list")&&statuses.get("remote_list").map((remote) => <li>{remote}</li>)}
                                    </div>
                                </div>
                            </div>

                               

                               
                               {statuses.has("__json_maintenance")&&statuses.get("__json_maintenance")}
                    
                        </div>
                        <div label={t('objects.details.menus.parameters')} icon="cog">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        {statuses.has("photocell_open_check")&&<h5>{t('objects.details.photocells')}</h5>}
                                        {statuses.has("photocell_open_check")&&<span>{statuses.get("photocell_open_check")}</span>}
                                    </div>
                                    <div className="col">
                                        {statuses.has("gate_mode")&&<h5>{t('objects.details.gate_mode')}</h5>}
                                        {statuses.has("gate_mode")&&<span>{statuses.get("gate_mode")}</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {statuses.has("motor_delay2")&&<h5>{t('objects.details.motor_delay')}</h5>}
                                        {statuses.has("motor_delay2")&&<span>{statuses.get("motor_delay2")} ms</span>}
                                    </div>
                                    <div className="col">
                                        {statuses.has("gate_autoclose_delay")&&<h5>{t('objects.details.autoclose_delay')}</h5>}
                                        {statuses.has("gate_autoclose_delay")&&<span>{statuses.get("gate_autoclose_delay")} ms</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {statuses.has("warning_preblink_time")&&<h5>{t('objects.details.preblink_time')}</h5>}
                                        {statuses.has("warning_preblink_time")&&<span>{statuses.get("warning_preblink_time")}</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {statuses.has("motor_strength")&&<h5>{t('objects.details.strength')}</h5>}
                                        {statuses.has("motor_strength")&&<span>{statuses.get("motor_strength")}</span>}
                                    </div>
                                    <div className="col">
                                        {statuses.has("motor_speed_min")&&<h5>{t('objects.details.min_speed')}</h5>}
                                        {statuses.has("motor_speed_min")&&<span>{statuses.get("motor_speed_min")}</span>}
                                    </div>
                                    <div className="col">
                                        {statuses.has("motor_speed_max")&&<h5>{t('objects.details.speed')}</h5>}
                                        {statuses.has("motor_speed_max")&&<span>{statuses.get("motor_speed_max")}</span>}
                                    </div>
                                    <div className="col">
                                        {statuses.has("motor_speedup_distance")&&<h5>{t('objects.details.speedup')}</h5>}
                                        {statuses.has("motor_speedup_distance")&&<span>{statuses.get("motor_speedup_distance")}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                    <div className="col">
                                        {statuses.has("motor_slowdown_distance")&&<h5>{t('objects.details.slowdown')}</h5>}
                                        {statuses.has("motor_slowdown_distance")&&<span>{statuses.get("motor_slowdown_distance")}</span>}
                                    </div>
                                    <div className="col">
                                        {statuses.has("stops_tolerance")&&<h5>{t('objects.details.tolerance')}</h5>}
                                        {statuses.has("stops_tolerance")&&<span>{statuses.get("stops_tolerance")} %</span>}
                                    </div>
                            </div>
                            <div className="row">
                                    <div className="col">
                                        {statuses.has("motor1_flip")&&<h5>{t('objects.details.motor1_flip')}</h5>}
                                        {statuses.has("motor1_flip")&&<span>{statuses.get("motor1_flip")}</span>}
                                    </div>
                                    <div className="col">
                                        {statuses.has("motor2_flip")&&<h5>{t('objects.details.motor2_flip')}</h5>}
                                        {statuses.has("motor2_flip")&&<span>{statuses.get("motor2_flip")}</span>}
                                    </div>
                            </div>
                            <div className="row">
                                    <div className="col">
                                        {statuses.has("gate_lock_time")&&<h5>{t('objects.details.lock_time')}</h5>}
                                        {statuses.has("gate_lock_time")&&<span>{statuses.get("gate_lock_time")} s</span>}
                                    </div>
                            </div>
                        </div>
                        <div label={t('objects.details.menus.history')} icon="calendar">
                            {values.hasOwnProperty('lastEvent')&&<h5>{t('objects.details.last_event')}</h5>}
                            {values.hasOwnProperty('lastEvent')&&<span>{dates.format({'language':'fr'},values.lastEvent)}</span>}
                            {statuses.has("errors_history")&&<h5>{t('objects.details.errors_history')}</h5>}
                            {statuses.has("errors_history")&&statuses.get("errors_history").map((error) => <li>{error}</li>)}
                            {statuses.has("commands_history")&&<h5>{t('objects.details.commands_history')}</h5>}
                            {statuses.has("commands_history")&&statuses.get("commands_history").map((command) => <li>{command}</li>)}
                        </div>
                        <div label={t('objects.details.menus.ota')} icon="upvote">
                            <p>URL du firmware</p>
                            <input
                                placeholder={t('objects.ota.url')}
                                type='text'
                                value={otaUrl}
                                onInput={e => setOtaUrl(e.target.value)}
                            />
                            <p>
                                <button className="button-ota" onClick={() => {otaAction(true)}}>{t('objects.upgrade')}</button>
                            </p>
                        </div>
                        <div label="routes" icon="upvote">
                            <ZigbeeRoutes objectId={values.id} />
                        </div>          
                    </Tabs>

                </div>
            }
        </Popup>
    )

};

export default SingleObject;
