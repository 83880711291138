import { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import config from '../../config'
import Select from 'react-select'
import AssService from '../../services/AssService'
import OtaService from '../../services/OtaService';
//import { Visibility } from '@tanstack/react-table'

/*--
    action[0] : pop state parameter
    action[1] : firmwareId
    action[2] : function to call when Popup is closed
--*/
const MassOTAPopUp = ({action,t}) => {
  
    const [openPopup,setOpenPopup] = useState (false);
    const [id, setId] = useState('');
    const [type, setType] = useState({})

    const [firmware, setFirmware] = useState({});
    const [filename, setFilename] = useState("");
    const [firmwareIsForProduction, setFirmwareIsForProduction] = useState(true);

    /*
    OtaService.getFirmware(action[1]).then(
        (result) => {
            setFirmware(result)
            console.log(result) 
        }
    )*/

    useEffect(()=>{
        if(openPopup === false && action[0] === true) //ouverture initiale
        {
            setType({});
            setId('');

            OtaService.getFirmware(action[1]).then(
                (result) => {
                    console.log(result.filename)
                    setFirmware(result)
                    setFilename(result.filename)
                    setFirmwareIsForProduction(OtaService.getFirmwareDetails(firmware.filename)[3])
                }
            ).catch((error) => {
                console.error(error);
              });
        }
        setOpenPopup(action[0])
    },[action])

    function onAdd() {
       
        var body = {}

        try {
            return AssService.createObject(action[1],body).then(()=>{
                action[2]();
                setOpenPopup(false);
            })
        } catch (e) {
          throw new Error(`API error:${e?.message}`)
        }    
    }

    return (
        <Popup open={openPopup} closeOnDocumentClick modal className='addPopUp'>
            <h5>{t('firmwares.popup.ota.title')}</h5>
            <div className='align-left'>
                {!firmwareIsForProduction&&<h6><box-icon type='solid' name='radiation'></box-icon>t('firmwares.popup.ota.warning')</h6>}
                <p>{t('firmwares.popup.ota.text')}</p>
                <p><b>{t('firmwares.popup.ota.file-label')}: </b>{filename}</p>
                <p><b>{t('firmwares.popup.ota.type-label')}: </b>{}</p>
            </div>
            <p></p>
            <button className="button" onClick={()=>{action[2]()}}>{t('objects.add.cancel')}</button>
            <button className="button" onClick={onAdd}>{t('objects.add.validate')}</button>
        </Popup>
    );
    
}
  
export default MassOTAPopUp;