import { DurinClient } from './apiClient'

function OtaWorker (client) {
  this.client = client
  this.commands_queue = []
  this.devices = {}
}

OtaWorker.prototype.queueDevices = function (ids, url, wsConnector) {
  let gateways = new Map()
  let new_devices = []
  let requests = ids.map(id => AssService.getObject(id))

  Promise.all(requests).then((resources) => {
    resources.forEach(resource => {
      if (resource.connected && resource.realName.match(/^dev-.+\/IEEEAddr-.+/)) {
        let cell = {data: resource, upgrading: false}
        this.devices[resource.id] = cell
        this.devices[resource.realName] = cell

        new_devices.push(resource)
        gateways.set(resource.gateway.resource.id, {
          'userId': resource.user.resource.id,
          'name': resource.gateway.resource.name
        })
      }
    })

    /* First queue the image requests, then the notifications. */
    gateways.forEach((data, id) => {
      wsConnector.registerListener(data.name, function (msg) {
        return (msg.args.length > 2)
          && (((msg.cmd === 'message' && msg.args[1].startsWith('io/ezsp')))
              || ((msg.cmd === 'status') && msg.args[1].startsWith('transient/event')))
      }, this.onEvent)
      this.queue_command(this.send_image_request, data.userId.toString(), data.name, url)
    })

    new_devices.forEach(device => {
      this.queue_command(this.send_device_request, device.user.resource.id.toString(), device.id.toString(), "notify");
    })

    /* Bootstrap the process. */
    this.call_next_command()
  })
}

OtaWorker.prototype.is_queue_empty = function () {
  return this.commands_queue.length === 0
}

OtaWorker.prototype.queue_command = function () {
  this.commands_queue.push(Array.from(arguments))
}

OtaWorker.prototype.call_next_command = function () {
  let array = this.commands_queue.shift()

  if (array !== undefined) {
    let cmd = array.shift()
    cmd.apply(this, array)
  }
}

OtaWorker.prototype.onEvent = function (cmd, args) {
  if (cmd === 'status') {
    let status = args[1].substring(args[1].lastIndexOf('/') + 1)
    let device = this.devices[args[1].substring(24, args[1].lastIndexOf('/'))]

    console.log(args[1] + ' -> ' + args[2])

    if (status === 'ota-process') {
      let [property, value] = args[2].split(':')

      if (property === 'download') {
        /* - A percentage provides the download progression.
         * - An hexadecimal status value on error.
         */
        if (value.endsWith('%'))
          ; // TODO Report progress
        else
          ; // TODO Report error
      } else if (property === 'firmware') {
        /* - invalid if an operation has checked the firmware was absent or invalid.
         * - ok when a correct firmware is loaded.
         */
        if (value === 'ok')
          this.call_next_command()
        else
          this.commands_queue = []

        // TODO Report download result

      } else if (property === 'notification') {
        /* - ok when the Send Image Notify request is being handled.
         * - failed if the request cannot be sent.
         */
        if (value === 'ok')
          device.upgrading = true

        // TODO Report notification result

      } else if (property === 'upgrade') {
        /* - abort_requested when the Cancel button was used.
         * - aborted when the abort status is sent to the device.
         * - request when the gateway replied to proceed now.
         * - an hexadecimal status value when the device completes the process (0000 is success).
         */
        if (value === 'request' || value.startsWith('abort')) {
          // TODO Report upgrade status
        } else if (value !== '0000') {
          // TODO Report upgrade error
          this.call_next_command()
        } else {
          // TODO Report upgrade success
          this.call_next_command()
        }

        if (value !== 'request')
          device.upgrading = false
      }
    } else if (status === 'version') {
      // TODO Report new version
    }
  }
}

OtaWorker.prototype.send_device_request = async function (userId, objectId, request) {
  try {
    const response = await this.client.post('ota/zigbee', {
      userId,
      objectId,
      request
    })
    return response?.data
  } catch (error) {
    throw error
  }
}

OtaWorker.prototype.send_image_request = async function (userId, gatewayName, url) {
  try {
    const response = await this.client.post('ota/zigbee', {
      userId,
      gatewayName,
      request: 'image',
      url
    })
    return response?.data
  } catch (error) {
    throw error
  }
}

const AssService = {
  client: new DurinClient(),

  /**
   * Create a new object for some account.
   *
   * @param {Number} userId : the unique identifier of the account which will own the object.
   * @param {Object} properties : the object properties (see Durin documentation).
   * @returns {Object} : the created object.
   */
  createObject: async function(userId, properties) {
    try {
      console.log(userId)
      console.log(properties)
      const response = await this.client.post('/ass/objects', {
        userId,
        'object': properties
      })
      return response?.data
    } catch (error) {
      throw error // TODO: Handle error on object creation
    }
  },

  deleteObject: async function(objectId) {
    const response = await this.client.delete(['/ass/objects', objectId])
    return response
  },

  getObject: async function (objectId) {
    const response = await this.client.get(['/ass/objects', objectId])
    return response
  },

  getObjects: async function(criteria) {
    /* By default, a simplified selection if required. */
    criteria['user'] = ''

    return await this.client.get('/ass/objects', criteria)
  },

  getObjectsWithAllFilter: async function(criteria) {
    /* By default, a simplified selection if required. */
    criteria['all'] = ''

    const result = await this.client.get('/ass/objects', criteria)

    return result
  },

  getMobiles: async function(criteria) {
    criteria['sift:type'] = 'Mobile'

    return await this.client.get('/ass/objects', criteria)
  },

  getGateways: async function(criteria) {
    criteria['sift:type'] = 'Gateway'

    const response = await this.client.get('/ass/objects', criteria)
    //const response = await this.client.get('/ass/gateways', criteria)
    response?.data.forEach((element, index, array) => {
      if(element.realName == "System")
      {
        array.splice(index,1)
      }
    });
    
    return response.data
  },

   /**
   * Create a new zigbee gateway for some account.
   *
   * @param {Object} criteria : the gateway properties (see Durin documentation).
   * @returns {Object} : the created gateway.
   */
   createGateway: async function(criteria) {
    try {
      const response = await this.client.post('/ass/gateways', criteria)
      return response
    } catch (error) {
      throw error // TODO: Handle error on object creation
    }
  },

  deleteGateway: async function(gatewayId) {
    const response = await this.client.delete(['/ass/gateways', gatewayId])
    return response
  },

  /**
   * Send a batch OTA request with a set of criteria.
   *
   * The criteria parameters must be an objet, each entry defining a criterion.
   * The format of the objects is described in the Durin documentation for the
   * /ass/ota path in the chapter After-Sales Resources.
   *
   * @param {Number} url : the location of the OTA upgrade.
   * @param {Object} criteria : the request criteria, defined as above.
   */
  sendOtaUpgradeRequest: async function (url, criteria) {
    try {
      console.log(criteria)
      var response


      response = await this.client.post('/ass/ota', {
      criteria,
      url })

      
      return response?.data
    } catch (error) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.log(error.message);
        console.log(error.request);
        throw error // TODO: Handle error on SAV request
    }
  },

  sendSavRequest: async function (userId) {
    try {
      const response = await this.client.post('/ass/requests', { userId })
      return response?.data
    } catch (error) {
      throw error // TODO: Handle error on SAV request
    }
  },

  cancelSavRequest: async function (requestId) {
    try {
      const response = await this.client.put('/ass/requests/'+requestId, {'state': 'CANCELED'})
      return response?.data
    } catch (error) {
      throw error // TODO: Handle error on SAV request
    }
  },

  getSavRequestsStatus: async function (criteria) {
    return this.client.get('/ass/requests', criteria)
  },

  sendGatewayCommand: async function (userId,gatewayId,command) {
    try {
      var body = { 
        "userId": userId,
        "object": {
          "actions":[
            {
              "mArgs":command,
              "name":"COMMAND"}]
        }
      }
      console.log(body)
      const response = await this.client.put('/ass/objects/'+gatewayId, body)
      return response?.data
    } catch (error) {
      throw error // TODO: Handle error 
    }
  },
}

export default AssService
