import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import NetworkNav from './NetworkNav'
import Cartography from '../../components/Zdoctor/Cartography.js'


function NetworkActions ({ i18n, t }) {
  const {gatewayId } = useParams() 

  return (
    <>
      <NetworkNav title={t('users.network.title')} />
      <div className="content">

        <div class="row cartography">
          <div class="col">
              <h6>Cartographie</h6>
              <Cartography gatewayId={gatewayId}></Cartography>
          </div>
        </div>

        
      </div>  
    </>
  )
}

export default withTranslation('ui')(NetworkActions)
